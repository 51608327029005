<div class='dispatcher-contain'>
    <ng-container *ngIf="showDivSelector | async; else showAccountLabel">
    <div class="dispatcher-list" >
        <zui-searchable-dropdown
                label="Divisions"
                [isMultiple]="false"
                [options]="accountList"
                [sortByStringTitle]="true"
                [z_formGroup]="accountForm"
                [selectOptionsControlName]="'account'"
                (selected)="accountSelection($event)"
                [searchableThreshold]="4"
        >
        </zui-searchable-dropdown>
    </div>
    </ng-container>
    <ng-template #showAccountLabel>
        <div >
            <mat-label>
                <span class="toggle_id">Account:</span><span class="account-label">{{accountList[0].title}}</span>
            </mat-label>
        </div>
    </ng-template>
</div>
