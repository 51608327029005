// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
// 	production: false
// };
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	// TODO: If you listen to a different port for your app for local development, change this origin.
	appUrl: 'https://cd-melds-303-divs.dev.zonarsystems.net',
	authEnv: 'development',
	prefix: 'dev',
	name: 'development',
	region: 'NA',
	eldxApplicationId: '0c96e82d-da2d-4858-9169-b7729516a960',
	// TODO: Setup your application's authentication variables: see
	// https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
	// for instructions on how to do that.
	auth: {
		clientID: 'GWZewosjzXSilqqtGlE2VKKAQiwPsVUt',
		domain: 'zonar-dev.auth0.com',
		audience: 'http://apidev.zonarsystems.net/',
		// These come from the Core APIs as your application is built.
		applicationId: '7b848b7a-621c-4690-8f18-a59bf17b6edc',
		defaultZonarRole: '06751ce8-6150-4ae0-9c68-9406c687a4e2',
		useRefreshTokens: true
	},
	urls: {
		udlApi: `https://udl-service-ajh7az42pq-uw.a.run.app`,
		baseApi: `https://api-dev.zonarsystems.net`,
		externalApi: 'https://api-dev.zonarsystems.net/core/v1',
		hosBackendApi: `https://hos-reports-ajh7az42pq-uw.a.run.app`,
		legacyEntityApi: `https://legacy-entity-api-ajh7az42pq-uw.a.run.app`,
		eldx: 'https://eldx.dev.zonarsystems.net',
	},
	accountsWithAdmin: ['dbr47_rep1023'],
	excludeAccounts: ['foo'],
	production: false,
	useHereV3: true,
	datadog: {
		applicationId: 'c345ac2d-a240-464a-bc85-e3696c38716e',
		clientToken: 'pub3b706123189f56e5f8b1e610cf55e6f8',
		site: 'us5.datadoghq.com',
		service: 'compliance-dashboard',
	},
	devCycle: {
		clientId: 'dvc_client_ccad0b8d_bb59_42be_939c_836c5a854789_8eb19ec'
	},
	ciDevUrl: undefined
};

export const httpInterceptList =  [
	// core entity api base urls. these are used by the auth module to make calls to the user-api
	environment.urls.baseApi + '/*',
	environment.urls.externalApi + '/*',
	environment.urls.hosBackendApi + '/*',
	environment.urls.legacyEntityApi + '/*',
	environment.urls.udlApi + '/*',
	`/*`
];

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
